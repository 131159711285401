.ant-modal.editMail-Modal {
  width: 100% !important;
  top: 10px;
  height: calc(100% - 10px);
}
.ant-modal.editMail-Modal > .ant-modal-content {
  height: 100%;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body {
  height: 100%;
  padding: 12px;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper {
  height: 100%;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body {
  height: calc(100% - 56px);
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > span {
  display: none;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > .ant-modal-confirm-content {
  height: 100%;
  margin: 0;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > .ant-modal-confirm-content * {
  vertical-align: top;
}
.ant-modal.editMail-Modal > .ant-modal-content > .ant-modal-body > .ant-modal-confirm-body-wrapper > .ant-modal-confirm-body > .ant-modal-confirm-content .MailEditor-split {
  display: flex;
  flex-direction: row;
}
.split {
  display: flex;
  flex-direction: row;
}
.gutter {
  background-color: #eee;
  background-repeat: no-repeat;
  background-position: 50%;
}
.gutter.gutter-horizontal {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
  cursor: col-resize;
}
