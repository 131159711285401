.ant-tabs > .ant-tabs-nav {
  margin: 0 !important;
}
.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap {
  padding-left: 8px;
}
.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab {
  padding: 0;
}
.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-btn {
  user-select: none;
}
.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-btn > *:first-child {
  padding: 8px 16px;
}
.ant-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab > .ant-tabs-tab-remove {
  margin-right: 4px;
}
